import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9eecd980 = () => interopDefault(import('../pages/achievement/index.vue' /* webpackChunkName: "pages/achievement/index" */))
const _5e42cbb8 = () => interopDefault(import('../pages/advertise/index.vue' /* webpackChunkName: "pages/advertise/index" */))
const _f603944a = () => interopDefault(import('../pages/agreement/index.vue' /* webpackChunkName: "pages/agreement/index" */))
const _487c263a = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _09c1acfa = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _36e7c593 = () => interopDefault(import('../pages/details/index.vue' /* webpackChunkName: "pages/details/index" */))
const _c851b1de = () => interopDefault(import('../pages/goals/index.vue' /* webpackChunkName: "pages/goals/index" */))
const _44ed9fac = () => interopDefault(import('../pages/maisoku/index.vue' /* webpackChunkName: "pages/maisoku/index" */))
const _20b15635 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _3ebde956 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _38633daa = () => interopDefault(import('../pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _4f8643b4 = () => interopDefault(import('../pages/response/index.vue' /* webpackChunkName: "pages/response/index" */))
const _9d9360a8 = () => interopDefault(import('../pages/sale/index.vue' /* webpackChunkName: "pages/sale/index" */))
const _2a59e5fa = () => interopDefault(import('../pages/achievement/output.vue' /* webpackChunkName: "pages/achievement/output" */))
const _7336a93b = () => interopDefault(import('../pages/achievement/registration.vue' /* webpackChunkName: "pages/achievement/registration" */))
const _73c97934 = () => interopDefault(import('../pages/advertise/analysis.vue' /* webpackChunkName: "pages/advertise/analysis" */))
const _034748cd = () => interopDefault(import('../pages/advertise/management.vue' /* webpackChunkName: "pages/advertise/management" */))
const _f2890c7a = () => interopDefault(import('../pages/advertise/registration.vue' /* webpackChunkName: "pages/advertise/registration" */))
const _25f7c238 = () => interopDefault(import('../pages/calendar/registration.vue' /* webpackChunkName: "pages/calendar/registration" */))
const _273b1198 = () => interopDefault(import('../pages/customer/registration.vue' /* webpackChunkName: "pages/customer/registration" */))
const _4ce50526 = () => interopDefault(import('../pages/goals/analysis.vue' /* webpackChunkName: "pages/goals/analysis" */))
const _2b7ce062 = () => interopDefault(import('../pages/goals/setting.vue' /* webpackChunkName: "pages/goals/setting" */))
const _1f7e7943 = () => interopDefault(import('../pages/line/tracking/index.vue' /* webpackChunkName: "pages/line/tracking/index" */))
const _3b55f74a = () => interopDefault(import('../pages/maisoku/export.vue' /* webpackChunkName: "pages/maisoku/export" */))
const _14d9d4e0 = () => interopDefault(import('../pages/maisoku/template.vue' /* webpackChunkName: "pages/maisoku/template" */))
const _376a6f95 = () => interopDefault(import('../pages/response/registration.vue' /* webpackChunkName: "pages/response/registration" */))
const _008443d2 = () => interopDefault(import('../pages/sale/output.vue' /* webpackChunkName: "pages/sale/output" */))
const _544e434b = () => interopDefault(import('../pages/setting/announcement/index.vue' /* webpackChunkName: "pages/setting/announcement/index" */))
const _1954822e = () => interopDefault(import('../pages/setting/import/index.vue' /* webpackChunkName: "pages/setting/import/index" */))
const _e68e1dc2 = () => interopDefault(import('../pages/setting/mail-template.vue' /* webpackChunkName: "pages/setting/mail-template" */))
const _25d1e756 = () => interopDefault(import('../pages/setting/matching-mail.vue' /* webpackChunkName: "pages/setting/matching-mail" */))
const _7c50ec12 = () => interopDefault(import('../pages/setting/shop.vue' /* webpackChunkName: "pages/setting/shop" */))
const _ab9c9b1c = () => interopDefault(import('../pages/setting/staff/index.vue' /* webpackChunkName: "pages/setting/staff/index" */))
const _88eb93ac = () => interopDefault(import('../pages/setting/step-mail/index.vue' /* webpackChunkName: "pages/setting/step-mail/index" */))
const _96197f28 = () => interopDefault(import('../pages/setting/tag/index.vue' /* webpackChunkName: "pages/setting/tag/index" */))
const _f294a810 = () => interopDefault(import('../pages/shop/document-template/index.vue' /* webpackChunkName: "pages/shop/document-template/index" */))
const _cca7fada = () => interopDefault(import('../pages/shop/message/index.vue' /* webpackChunkName: "pages/shop/message/index" */))
const _c2fd31ea = () => interopDefault(import('../pages/message/line/edit.vue' /* webpackChunkName: "pages/message/line/edit" */))
const _26da2753 = () => interopDefault(import('../pages/message/line/log.vue' /* webpackChunkName: "pages/message/line/log" */))
const _7c68ac3d = () => interopDefault(import('../pages/message/line/registration/index.vue' /* webpackChunkName: "pages/message/line/registration/index" */))
const _6c672ebd = () => interopDefault(import('../pages/message/line/select.vue' /* webpackChunkName: "pages/message/line/select" */))
const _ed424cee = () => interopDefault(import('../pages/message/line/send.vue' /* webpackChunkName: "pages/message/line/send" */))
const _6053ebd4 = () => interopDefault(import('../pages/message/mail/log.vue' /* webpackChunkName: "pages/message/mail/log" */))
const _a335fc52 = () => interopDefault(import('../pages/message/mail/registration.vue' /* webpackChunkName: "pages/message/mail/registration" */))
const _44a0b11a = () => interopDefault(import('../pages/message/mail/select.vue' /* webpackChunkName: "pages/message/mail/select" */))
const _004161b4 = () => interopDefault(import('../pages/message/mail/send.vue' /* webpackChunkName: "pages/message/mail/send" */))
const _1078e6d0 = () => interopDefault(import('../pages/setting/announcement/registration.vue' /* webpackChunkName: "pages/setting/announcement/registration" */))
const _f2f1ec2c = () => interopDefault(import('../pages/setting/import/Detail.vue' /* webpackChunkName: "pages/setting/import/Detail" */))
const _191abd72 = () => interopDefault(import('../pages/setting/import/registration.vue' /* webpackChunkName: "pages/setting/import/registration" */))
const _0480d5c2 = () => interopDefault(import('../pages/setting/real-estate-agent/property-range.vue' /* webpackChunkName: "pages/setting/real-estate-agent/property-range" */))
const _5af86dc9 = () => interopDefault(import('../pages/setting/staff/registration.vue' /* webpackChunkName: "pages/setting/staff/registration" */))
const _7a6ecb11 = () => interopDefault(import('../pages/setting/step-mail/registration.vue' /* webpackChunkName: "pages/setting/step-mail/registration" */))
const _af92cba4 = () => interopDefault(import('../pages/message/line/registration/success.vue' /* webpackChunkName: "pages/message/line/registration/success" */))
const _43aee1c0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _64b39653 = () => interopDefault(import('../pages/message/mail/edit/_id.vue' /* webpackChunkName: "pages/message/mail/edit/_id" */))
const _04bbe290 = () => interopDefault(import('../pages/agreement/log/_id.vue' /* webpackChunkName: "pages/agreement/log/_id" */))
const _79fb1feb = () => interopDefault(import('../pages/agreement/registration/_id.vue' /* webpackChunkName: "pages/agreement/registration/_id" */))
const _3ec1ae94 = () => interopDefault(import('../pages/customer/edit/_id.vue' /* webpackChunkName: "pages/customer/edit/_id" */))
const _ecefb95e = () => interopDefault(import('../pages/media/registration/_id.vue' /* webpackChunkName: "pages/media/registration/_id" */))
const _39a070bc = () => interopDefault(import('../pages/project/registration/_id.vue' /* webpackChunkName: "pages/project/registration/_id" */))
const _4be20ec0 = () => interopDefault(import('../pages/response/add/_id.vue' /* webpackChunkName: "pages/response/add/_id" */))
const _3ecac71a = () => interopDefault(import('../pages/setting/announcement/_id.vue' /* webpackChunkName: "pages/setting/announcement/_id" */))
const _070da95a = () => interopDefault(import('../pages/setting/staff/_id.vue' /* webpackChunkName: "pages/setting/staff/_id" */))
const _65a3bb12 = () => interopDefault(import('../pages/setting/step-mail/_id.vue' /* webpackChunkName: "pages/setting/step-mail/_id" */))
const _f120e48a = () => interopDefault(import('../pages/shop/message/_id.vue' /* webpackChunkName: "pages/shop/message/_id" */))
const _283934b0 = () => interopDefault(import('../pages/achievement/_id.vue' /* webpackChunkName: "pages/achievement/_id" */))
const _227419fa = () => interopDefault(import('../pages/agreement/_id.vue' /* webpackChunkName: "pages/agreement/_id" */))
const _479d6ddf = () => interopDefault(import('../pages/client-history/_id.vue' /* webpackChunkName: "pages/client-history/_id" */))
const _645fb0ab = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _1ccd3c46 = () => interopDefault(import('../pages/media/_id.vue' /* webpackChunkName: "pages/media/_id" */))
const _a7cebadc = () => interopDefault(import('../pages/project/_id.vue' /* webpackChunkName: "pages/project/_id" */))
const _5156fc1c = () => interopDefault(import('../pages/property/_property.vue' /* webpackChunkName: "pages/property/_property" */))
const _e51d8d20 = () => interopDefault(import('../pages/property/_property/contact/index.vue' /* webpackChunkName: "pages/property/_property/contact/index" */))
const _0d1eb244 = () => interopDefault(import('../pages/property/_property/contact/confirm.vue' /* webpackChunkName: "pages/property/_property/contact/confirm" */))
const _3fe36e5a = () => interopDefault(import('../pages/property/_property/contact/send.vue' /* webpackChunkName: "pages/property/_property/contact/send" */))
const _a4cf9be4 = () => interopDefault(import('../pages/response/_id.vue' /* webpackChunkName: "pages/response/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/achievement",
    component: _9eecd980,
    name: "achievement"
  }, {
    path: "/advertise",
    component: _5e42cbb8,
    name: "advertise"
  }, {
    path: "/agreement",
    component: _f603944a,
    name: "agreement"
  }, {
    path: "/calendar",
    component: _487c263a,
    name: "calendar"
  }, {
    path: "/customer",
    component: _09c1acfa,
    name: "customer"
  }, {
    path: "/details",
    component: _36e7c593,
    name: "details"
  }, {
    path: "/goals",
    component: _c851b1de,
    name: "goals"
  }, {
    path: "/maisoku",
    component: _44ed9fac,
    name: "maisoku"
  }, {
    path: "/media",
    component: _20b15635,
    name: "media"
  }, {
    path: "/privacy",
    component: _3ebde956,
    name: "privacy"
  }, {
    path: "/project",
    component: _38633daa,
    name: "project"
  }, {
    path: "/response",
    component: _4f8643b4,
    name: "response"
  }, {
    path: "/sale",
    component: _9d9360a8,
    name: "sale"
  }, {
    path: "/achievement/output",
    component: _2a59e5fa,
    name: "achievement-output"
  }, {
    path: "/achievement/registration",
    component: _7336a93b,
    name: "achievement-registration"
  }, {
    path: "/advertise/analysis",
    component: _73c97934,
    name: "advertise-analysis"
  }, {
    path: "/advertise/management",
    component: _034748cd,
    name: "advertise-management"
  }, {
    path: "/advertise/registration",
    component: _f2890c7a,
    name: "advertise-registration"
  }, {
    path: "/calendar/registration",
    component: _25f7c238,
    name: "calendar-registration"
  }, {
    path: "/customer/registration",
    component: _273b1198,
    name: "customer-registration"
  }, {
    path: "/goals/analysis",
    component: _4ce50526,
    name: "goals-analysis"
  }, {
    path: "/goals/setting",
    component: _2b7ce062,
    name: "goals-setting"
  }, {
    path: "/line/tracking",
    component: _1f7e7943,
    name: "line-tracking"
  }, {
    path: "/maisoku/export",
    component: _3b55f74a,
    name: "maisoku-export"
  }, {
    path: "/maisoku/template",
    component: _14d9d4e0,
    name: "maisoku-template"
  }, {
    path: "/response/registration",
    component: _376a6f95,
    name: "response-registration"
  }, {
    path: "/sale/output",
    component: _008443d2,
    name: "sale-output"
  }, {
    path: "/setting/announcement",
    component: _544e434b,
    name: "setting-announcement"
  }, {
    path: "/setting/import",
    component: _1954822e,
    name: "setting-import"
  }, {
    path: "/setting/mail-template",
    component: _e68e1dc2,
    name: "setting-mail-template"
  }, {
    path: "/setting/matching-mail",
    component: _25d1e756,
    name: "setting-matching-mail"
  }, {
    path: "/setting/shop",
    component: _7c50ec12,
    name: "setting-shop"
  }, {
    path: "/setting/staff",
    component: _ab9c9b1c,
    name: "setting-staff"
  }, {
    path: "/setting/step-mail",
    component: _88eb93ac,
    name: "setting-step-mail"
  }, {
    path: "/setting/tag",
    component: _96197f28,
    name: "setting-tag"
  }, {
    path: "/shop/document-template",
    component: _f294a810,
    name: "shop-document-template"
  }, {
    path: "/shop/message",
    component: _cca7fada,
    name: "shop-message"
  }, {
    path: "/message/line/edit",
    component: _c2fd31ea,
    name: "message-line-edit"
  }, {
    path: "/message/line/log",
    component: _26da2753,
    name: "message-line-log"
  }, {
    path: "/message/line/registration",
    component: _7c68ac3d,
    name: "message-line-registration"
  }, {
    path: "/message/line/select",
    component: _6c672ebd,
    name: "message-line-select"
  }, {
    path: "/message/line/send",
    component: _ed424cee,
    name: "message-line-send"
  }, {
    path: "/message/mail/log",
    component: _6053ebd4,
    name: "message-mail-log"
  }, {
    path: "/message/mail/registration",
    component: _a335fc52,
    name: "message-mail-registration"
  }, {
    path: "/message/mail/select",
    component: _44a0b11a,
    name: "message-mail-select"
  }, {
    path: "/message/mail/send",
    component: _004161b4,
    name: "message-mail-send"
  }, {
    path: "/setting/announcement/registration",
    component: _1078e6d0,
    name: "setting-announcement-registration"
  }, {
    path: "/setting/import/Detail",
    component: _f2f1ec2c,
    name: "setting-import-Detail"
  }, {
    path: "/setting/import/registration",
    component: _191abd72,
    name: "setting-import-registration"
  }, {
    path: "/setting/real-estate-agent/property-range",
    component: _0480d5c2,
    name: "setting-real-estate-agent-property-range"
  }, {
    path: "/setting/staff/registration",
    component: _5af86dc9,
    name: "setting-staff-registration"
  }, {
    path: "/setting/step-mail/registration",
    component: _7a6ecb11,
    name: "setting-step-mail-registration"
  }, {
    path: "/message/line/registration/success",
    component: _af92cba4,
    name: "message-line-registration-success"
  }, {
    path: "/",
    component: _43aee1c0,
    name: "index"
  }, {
    path: "/message/mail/edit/:id?",
    component: _64b39653,
    name: "message-mail-edit-id"
  }, {
    path: "/agreement/log/:id?",
    component: _04bbe290,
    name: "agreement-log-id"
  }, {
    path: "/agreement/registration/:id?",
    component: _79fb1feb,
    name: "agreement-registration-id"
  }, {
    path: "/customer/edit/:id?",
    component: _3ec1ae94,
    name: "customer-edit-id"
  }, {
    path: "/media/registration/:id?",
    component: _ecefb95e,
    name: "media-registration-id"
  }, {
    path: "/project/registration/:id?",
    component: _39a070bc,
    name: "project-registration-id"
  }, {
    path: "/response/add/:id?",
    component: _4be20ec0,
    name: "response-add-id"
  }, {
    path: "/setting/announcement/:id",
    component: _3ecac71a,
    name: "setting-announcement-id"
  }, {
    path: "/setting/staff/:id",
    component: _070da95a,
    name: "setting-staff-id"
  }, {
    path: "/setting/step-mail/:id?",
    component: _65a3bb12,
    name: "setting-step-mail-id"
  }, {
    path: "/shop/message/:id",
    component: _f120e48a,
    name: "shop-message-id"
  }, {
    path: "/achievement/:id",
    component: _283934b0,
    name: "achievement-id"
  }, {
    path: "/agreement/:id",
    component: _227419fa,
    name: "agreement-id"
  }, {
    path: "/client-history/:id?",
    component: _479d6ddf,
    name: "client-history-id"
  }, {
    path: "/customer/:id",
    component: _645fb0ab,
    name: "customer-id"
  }, {
    path: "/media/:id",
    component: _1ccd3c46,
    name: "media-id"
  }, {
    path: "/project/:id",
    component: _a7cebadc,
    name: "project-id"
  }, {
    path: "/property/:property?",
    component: _5156fc1c,
    name: "property-property",
    children: [{
      path: "contact",
      component: _e51d8d20,
      name: "property-property-contact"
    }, {
      path: "contact/confirm",
      component: _0d1eb244,
      name: "property-property-contact-confirm"
    }, {
      path: "contact/send",
      component: _3fe36e5a,
      name: "property-property-contact-send"
    }]
  }, {
    path: "/response/:id",
    component: _a4cf9be4,
    name: "response-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
